export default [
   {
      icon: "/static/images/chainsense/services/1.jpg",
      title:"Blockchain Development",
      info: "<p>At Chainsense, we provide a range of blockchain application development services across various verticles with transparency, security, automation, and efficiency. If you want to be at the forefront of blockchain innovation, we offer you our expertise, skills, and capabilities.</p><p>We enable various industries such as financial services, healthcare, online streaming, real-estate, and more to transform their core functionality and efficiency with blockchain technology. Our public and private blockchain-based industrial solutions bring automation security, traceability, and immutability, across business processes.</p>",
   },
   {
      icon: "/static/images/chainsense/services/2.jpeg",
      title:"Ecosystem Building",
      info: "<p>Blockchain ecosystems are made up of pieces that can interact with one another and the outside world to create a unique environment. A blockchain ecosystem can also be defined as the agreed-upon governance structure for a particular use case. For example, participants’ acceptable behaviour, data ownership, funding, exit and admittance requirements, and conditions for information sharing among participants are all defined in the governance structure.</p>",
   },
   {
      icon: "/static/images/chainsense/services/3.jpeg",
      title:"Blockchain Interoperability",
      info: "<p>Cross Chain technology is increasingly becoming the ultimate solution for enhancing interoperability between blockchains. To simplify, cross-chain technology is an emerging technology that seeks to transmit value and information between different blockchain networks. The private blockchain currently held the largest market size in 2020. A shared database or ledger is secured by traditional security techniques, such as limited user rights.</p>",
   },
   {
      icon: "/static/images/chainsense/services/4.jpeg",
      title:"Validator Management",
      info: "<p>With the help of validator management, easily manage and verify transactions on your blockchain. Once transactions are verified, they are added to the distributed ledger. The proof of work (PoW) systems help in solving complex computational problems to verify transactions. This mechanism helps secure the network by imposing the need to lock up value in the network in order to participate in the consensus decisions.</p>",
   },
   {
      icon: "/static/images/chainsense/services/5.jpeg",
      title:"RPC Management",
      info: "<p>With some blockchain architects still using RESTful APIs, many are focusing on using RPC (Remote Procedure Call) for a more direct connection to the blockchain. RPC is a simple yet well-understood means of client/server communication. RPC helps with a lightweight data-interchange format that can represent numbers, ordered sequences of values, and collections of value pairs.</p><p>With Blockchain bearing the potential to impact many sectors, from financial services to the public sector to the healthcare and media industry. In India alone, government bodies, consultancy firms, technology giants, and start-ups are coming together on multiple platforms to explore Blockchain as a technology.</p>",
   },
  
]