<template>
  <div class="emb-aboutus-wrap">
        <div class="emb-about-content">
      <div class="about-page section-gap">
        <v-container grid-list-xl>
          <v-layout row wrap align-center>
            <v-flex xs12 xl pr-5>
              <h3 class="font-weight-bold font-italic mb-6">
                We delivering a business oriented niche solution with an
                integrated custom consensus algorithm within the blockchain
                architecture for a comprehensive solution.
              </h3>
              <p class="mb-6">
                Blockchain is evolving with time and the crypto market is the biggest proof of that. There are networks that can process thousands of transactions which was not possible when the blockchain had made a debut. It still has a long to go before it becomes a norm in the blockchain industry. The ripple effect has already begun. There are regions and hospitals that are experimenting with the technology for daily implications, pharmaceutical firms utilizing blockchain for SCM (Supply Chain Management), and researchers using  DLTs to access databases for clinical trial and drugs records. So it’s fairly safe to say Blockchain is Inevitable, it is going to transform the healthcare sector in coming time.
              </p>
            </v-flex>
          </v-layout>
        </v-container>
      </div>
      <div class="about-info section-gap bg-grey wow  ">
        <v-container grid-list-xl py-0>
          <v-layout
            row
            wrap
            align-center
            mb-md-0
            mb-6
            v-for="(item, index) in ourServices"
            :key="index"
            
          >
            <template v-if="index % 2">
              <v-flex xl6 xs12 md6 lg6 class="pl-12">
                <h2 class="font-weight-bold">
                  {{ item.title }}
                </h2>
                <div v-html="item.info"></div>
              </v-flex>
              <v-flex xl6 sm12 xs12 md6 lg6 text-md-left text-center>
                
                <img
                  :src="item.icon? item.icon : '/static/images/our-mission.jpg'"
                  alt="mission"
                />
              </v-flex>
            </template>
            <template v-else>
              <v-flex xl6 sm12 xs12 md6 lg6 text-md-left text-center>
                <img
                  :src="item.icon? item.icon : '/static/images/our-mission.jpg'"
                  alt="mission"
                />
              </v-flex>
              <v-flex xl6 xs12 md6 lg6 class="pl-12">
                <h2 class="font-weight-bold">
                  {{ item.title }}
                </h2>
               <div v-html="item.info"></div>
              </v-flex>
            </template>
          </v-layout>
        </v-container>
      </div>
    </div>
  </div>
</template>

<script>
import ourServices from "../assets/data/ourServices";
export default {
  components: {},
  data() {
    return {
      /**
       * Slick Options
       **/
      ourServices,
      slickOptions: {
        autoplay: false,
        slidesToShow: 1,
        infinite: false,
        arrows: false,
        dots: false,
        slidesToScroll: 1,
        swipe: true,
        seed: 100,
        rtl: this.rtlLayout,
        responsive: [
          {
            breakpoint: 390,
          },
        ],
      },
      /**
       *** Data
       **/
      reviews: [
        {
          img: "/static/images/user-1.jpg",
          name: "Albert Gomez",
          position: "Head, ABS Infos",
          desc: "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Autexpedita quasi voluptatibus tenetur nisi ad earum atque laboriosam accusamus. Doloremque sapiente aliquid quae. Animi ratione dolorum repellat amet nihil? Magnam?",
        },
        {
          img: "/static/images/user-2.jpg",
          name: "Tristan Cyrus",
          position: "Head, ABS Infos",
          desc: "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Autexpedita quasi voluptatibus tenetur nisi ad earum atque laboriosam accusamus. Doloremque sapiente aliquid quae. Animi ratione dolorum repellat amet nihil? Magnam?",
        },
        {
          img: "/static/images/user-3.jpg",
          name: "Kendrick Younes",
          position: "Head, ABS Infos",
          desc: "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Autexpedita quasi voluptatibus tenetur nisi ad earum atque laboriosam accusamus. Doloremque sapiente aliquid quae. Animi ratione dolorum repellat amet nihil? Magnam?",
        },
        {
          img: "/static/images/user-4.jpg",
          name: "Beljium Hugh",
          position: "Head, ABS Infos",
          desc: "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Autexpedita quasi voluptatibus tenetur nisi ad earum atque laboriosam accusamus. Doloremque sapiente aliquid quae. Animi ratione dolorum repellat amet nihil? Magnam?",
        },
      ],
      teamListing: null,
    };
  },
  mounted() {
  },
  methods: {},
};
</script>

<style scoped>
img[alt="mission"] {
    border-radius: 65px;
    filter: grayscale(0.7);
}
</style>